<template>
  <div id="wrapper" class="">
    <div class="loader" v-if="loading"></div>
    <main class="content" v-show="!loading">
      <div class="container">
        <div>
          <div
            class="row contract-detail-upper"
            v-if="item && !item['is_contract_done']"
          >
            <div class="col-12 col-lg-9 top-left">
              <!--col-9-->
              <div class="cardWhite">
                <div class="row no-gutters h-100">
                  <div class="col-12 col-xl-4 col-sm-6">
                    <!--col-12-->
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item.property_details.location ?? null"
                        class="formControl"
                      />
                      <label class="fieldFloating__label">Listing</label>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-6">
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item ? item.contract_length + ' Months' : '0'"
                        class="formControl"
                      />
                      <label class="fieldFloating__label"
                        >Contract Length</label
                      >
                    </div>
                  </div>
                  <div class="col-xl-2 col-sm-6">
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item.start_date | getFormatDate"
                        class="formControl"
                      />
                      <label class="fieldFloating__label">Start Date</label>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-3">
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="
                          item
                            ? item['layout'] + '(' + item['capacity'] + ')'
                            : null
                        "
                        class="formControl"
                      />
                      <label class="fieldFloating__label"
                        >Layout Design (Capacity)</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="cardBlue">
                <div class="row no-gutters">
                  <div class="col-6">
                    <p class="small-p">Monthly Cost Per Person</p>
                  </div>
                  <div class="col-6">
                    <p class="small-p align-right">
                      {{
                        item
                          ? item.final_price / item.capacity
                          : null | toCurrency
                      }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="med-p">Total Monthly Cost</p>
                  </div>
                  <div class="col-6">
                    <p class="med-p align-right">
                      {{ item.final_price | toCurrency }}
                      <!-- {{
                                                (item.final_price *
                                                    item.contract_length)
                                                    | toCurrency
                                            }} -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3 top-right">
              <div class="cardWhite userCard">
                <div class="row">
                  <div class="col-lg-4 col-sm-2 col-3 mlAuto mrAuto">
                    <img
                      :src="baseAPI + item.property_details.manager_image"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="col-xl-8 col-12 user-texts">
                    <div class="user-text-top">
                      <p class="userName">
                        {{ item.property_details.manager_name }}
                      </p>
                      <p class="userRole">Property Manager</p>
                    </div>
                    <div class="user-text-bottom">
                      <p class="userName">
                        {{ item.property_details.manager_phone_number }}
                      </p>
                      <p class="userMail">
                        <a>{{ item.property_details.manager_email }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-9">
              <div class="cardWhite offer-card">
                <div
                  class="d-flex justify-content-sm-end justify-content-center flex-sm-nowrap flex-wrap"
                >
                  <div class="button-allign">
                    <div class="btnGroup">
                      <a
                        :href="
                          item
                            ? `${baseAPI}${item.terms_and_condition_file}`
                            : null
                        "
                        download="download"
                        target="_blank"
                      >
                        <label class="btn btn1">
                          Download Contract
                          <input
                            id="attachments"
                            name="attachments"
                            title="Upgrade Space"
                            class="btn btn--blueLight uploadFile"
                          />
                        </label>
                      </a>
                    </div>
                  </div>
                  <div
                    class="button-allign"
                    v-if="item && item['approved_offer']"
                  >
                    <div class="btnGroup">
                      <label class="btn btn1">
                        Submit Signed Contract
                        <input
                          id="attachments"
                          name="attachments"
                          type="file"
                          accept="application/pdf"
                          @change="onUpload"
                          title="Upgrade Space"
                          class="btn btn--blueLight uploadFile"
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    class="button-allign"
                    v-if="item && !item['is_contract_done']"
                  >
                    <div class="btnGroup">
                      <label class="btn btn1">
                        {{ uploadbuttontext }}
                        <input
                          id="attachments"
                          name="attachments"
                          type="file"
                          accept="application/pdf"
                          @change="onUpload"
                          title="Upgrade Space"
                          class="btn btn--blueLight uploadFile"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div class="" v-if="item && !item['is_contract_done']">
                  <div
                    class=""
                    v-if="
                      item && !item['allow_submit'] && !item['approved_offer']
                    "
                  >
                    <label class="d-block mb10">Make an offer</label>
                    <div class="d-flex flex-md-nowrap flex-wrap mt-label">
                      <!-- <input
                                                type="number"
                                                step="0.01"
                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                v-model.number="offerPrice"
                                                class="offer-price-input"
                                            /> -->

                      <vue-numeric
                        currency="$"
                        separator=","
                        v-model.number="offerPrice"
                        v-bind:precision="2"
                      ></vue-numeric>

                      <input
                        type="tel"
                        v-mask="'(###) ###-####'"
                        v-model="phoneNumber"
                        placeholder="Phone Number"
                        class=""
                      />
                      <!-- {{phoneNumber?.length }} -->

                      <label
                        class="btn submit-offer col-md-auto"
                        @click="submitOffer(item)"
                        :disabled="submitLoading"
                      >
                        {{ submitLoading ? "Please Wait..." : "Submit offer" }}
                      </label>
                    </div>
                    <span v-if="$v.offerPrice.$error" class="text-danger"
                      >Capacity is required</span
                    >
                    <span v-if="$v.phoneNumber.$error" class="text-danger"
                      >Phone Number is required</span
                    >
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      Your offered price :
                      {{
                        item.offer_details
                          ? item.offer_details.offered_price
                          : "" | toCurrency
                      }}
                    </p>
                    <p class="text-capitalize">
                      Your offer status :
                      <span style="text-transform: capitalize">
                        {{
                          item.offer_details ? item.offer_details.status : ""
                        }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="p-1" v-else>
                  <div class="float-left d-flex flex-wrap">
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item ? item.contract_length + ' Months' : null"
                        class="formControl"
                      />
                      <label class="fieldFloating__label"
                        >Contract Length</label
                      >
                    </div>
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item ? item['layout'] : null"
                        class="formControl"
                      />
                      <label class="fieldFloating__label">Layout Design</label>
                    </div>
                    <div class="search__field fieldFloating">
                      <input
                        type="text"
                        placeholder=" "
                        readonly
                        :value="item ? item['capacity'] : null"
                        class="formControl"
                      />
                      <label class="fieldFloating__label">Head Count</label>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap">
                    <div
                      class="renewContract button-allign col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
                    >
                      <button
                        class="btn btn--blue w100 mb20 contectUs"
                        title="Renew Contract"
                        @click="renewContract(item['property_id'])"
                      >
                        Renew Contract
                      </button>
                    </div>
                    <div
                      class="renewContract button-allign col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5"
                    >
                      <button
                        class="btn btn--blueLight w100 mb20 contectUs"
                        title="Contact Us"
                        @click="CHANGE_CONTECT_US_POP_UP(true)"
                      >
                        Contact us
                      </button>
                    </div>
                    <div class="button-allign col-1">
                      <button class="btn btn--blueLight">
                        <i class="fa fa-info-circle icon-blue"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pdfView">
                <div
                  class="d-flex flex-md-row flex-column align-items-md-center justify-content-between pdfView__header"
                >
                  <div class="pdfView__title">
                    Terms & Conditions for Membership
                  </div>
                  <div
                    class="pdfView__revision"
                    v-if="item && item.last_revised"
                  >
                    <strong>Last Revision</strong>
                    {{
                      item && item.last_revised != null
                        ? item.last_revised
                        : "" | getDateData
                    }}
                  </div>
                </div>
                <div class="textCenter pdfView__body">
                  <iframe
                    :src="
                      item ? `${baseAPI}${item.terms_and_condition_file}` : null
                    "
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="widget widget--green">
                <div class="widget__title">Property</div>
                <ul class="list">
                  <li><i class="fas fa-check"></i>Selected</li>
                </ul>
              </div>
              <div
                class="widget"
                :class="
                  item && item['signed'] && item['approved']
                    ? 'widget--green'
                    : (item && item['signed']) || (item && item['approved'])
                    ? 'widget--yellow'
                    : 'widget--gray'
                "
              >
                <div class="widget__title">Contract</div>
                <ul class="list">
                  <li>
                    <i class="fas fa-check" v-if="item && item['signed']"></i>
                    <i class="fas fa-dash" v-else></i>Signed
                  </li>
                  <li>
                    <i class="fas fa-check" v-if="item && item['approved']"></i>
                    <i class="fas fa-dash" v-else></i>Approved
                  </li>
                </ul>
              </div>
              <div
                class="widget"
                :class="
                  item &&
                  item['first_rent'] &&
                  item['last_rent'] &&
                  item['add_on_cost']
                    ? 'widget--green'
                    : item &&
                      (item['first_rent'] ||
                        item['last_rent'] ||
                        item['add_on_cost'])
                    ? 'widget--yellow'
                    : 'widget--gray'
                "
              >
                <div class="widget__title">Up-Front Payments</div>
                <ul class="list">
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['first_rent']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>First Month Rent
                  </li>
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['last_rent']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Last Month Rent
                  </li>
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['add_on_cost']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Add-On Costs
                  </li>
                </ul>
              </div>
              <div
                class="widget"
                :class="
                  item &&
                  item['materials_ordered'] &&
                  item['assembly_started'] &&
                  item['setup_completed']
                    ? 'widget--green'
                    : item &&
                      (item['materials_ordered'] ||
                        item['assembly_started'] ||
                        item['setup_completed'])
                    ? 'widget--yellow'
                    : 'widget--gray'
                "
              >
                <div class="widget__title">Setup</div>
                <ul class="list">
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['materials_ordered']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Materials Ordered
                  </li>
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['assembly_started']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Assembly Started
                  </li>
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['setup_completed']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Setup Completed
                  </li>
                </ul>
              </div>
              <div
                class="widget"
                :class="
                  item && item['send_via_fedex'] && item['arrived_in_mail']
                    ? 'widget--green'
                    : item &&
                      (item['send_via_fedex'] || item['arrived_in_mail'])
                    ? 'widget--yellow'
                    : 'widget--gray'
                "
              >
                <div class="widget__title">Access Cards</div>
                <ul class="list">
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['send_via_fedex']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Sent via FedEx
                  </li>
                  <li>
                    <i
                      class="fas fa-check"
                      v-if="item && item['arrived_in_mail']"
                    ></i>
                    <i class="fas fa-dash" v-else></i>Arrived In Mailbox
                  </li>
                </ul>
              </div>
              <div
                class="widget widget--total textCenter"
                v-if="item && item['is_contract_done']"
              >
                <span>Congrats! Your space is ready!</span>
              </div>
              <p v-else class="download_note">
                Note: Please download the contract and upload once revised and
                signed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getContract, contractFileUpload, makeOffer } from "@/api/contracts";
import moment from "moment";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from "vuex";
import VueNumeric from "vue-numeric";
import { mask } from "vue-the-mask";

export default {
  name: "DetailProperty",
  directives: { mask },
  data() {
    return {
      uploadbuttontext: "Upload Revised Contract",
      item: null,
      baseAPI: process.env.VUE_APP_BASE_API,
      id: null,
      attachments: [],
      conditionFile: 0,
      data: "",
      loading: true,
      submitLoading: false,
      offerPrice: null,
      phoneNumber: null,
      previousOfferPrice: null,
      minimunOffer: 100,
    };
  },
  filters: {
    getDateData(data) {
      data = new Date(data);
      let year = data.getFullYear();
      let date = data.getDate();
      let month = data.getMonth() + 1;

      let dateData = date + "/" + month + "/" + year;

      return moment(new Date()).format("DD/MM/YYYY");

      //return dateData;
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  computed: {
    isContractDone() {
      return this.contractStatus == "done";
    },
    getDate() {
      if (this.item) {
        var temp1 = new Date(this.item.start_date);
        let dt1 =
          temp1.getDate() +
          "/" +
          temp1.getMonth() +
          1 +
          "/" +
          temp1.getFullYear();

        var temp2 = new Date(this.item.start_date);
        temp2.setMonth(temp2.getMonth() + Number(this.item.contract_length));
        let dt2 =
          temp2.getDate() +
          "/" +
          temp2.getMonth() +
          1 +
          "/" +
          temp2.getFullYear();
        return dt1 + " - " + dt2;
      }
      return "";
    },
  },
  created() {
    this.id = atob(this.$router.history.current.params.contractId);
    this.loading = true;
    this.getContractDetails();
    // getContract(this.id)
    //     .then((res) => {
    //         this.loading = false;
    //         this.item = (res.data && res.data.data) || [];
    //     })
    //     .catch((err) => {
    //     });
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),
    renewContract(id) {
      this.$router.push("/detail/" + id);
    },
    getContractDetails() {
      getContract(this.id)
        .then((res) => {
          this.loading = false;
          this.item = (res.data && res.data.data) || [];
          this.offerPrice = this.item.final_price;
          if (this.item.success) {
            this.offerPrice = this.item.final_price;
          }
          // } else {
          //   this.$notify({
          //     group: "notify",
          //     type: "sucess",
          //     text: res.data.message,
          //   });
          // this.$router.go(-1);
          // }
        })
        .catch((err) => {
          console.log("error", err);
          // let data = err.response.data;
          this.$notify({
            group: "notify",
            type: "error",
            text: "Failed to get contract.",
          });
          this.$router.go(-1);
        });
    },
    submitOffer(item) {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      if (this.offerPrice < this.minimunOffer) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "Your Offer can not be less than " + this.minimunOffer + ".",
        });
        return;
      }
      this.submitLoading = true;
      const requestData = {
        contract_id: item.id,
        user_id: item.user_details.id,
        offered_price: this.offerPrice,
        phone_no: this.phoneNumber,
      };
      console.log("requestData", requestData);
      makeOffer(requestData)
        .then((res) => {
          this.submitLoading = false;
          let response = (res.data && res.data.data) || [];
          console.log("response", response);
          this.$notify({
            group: "notify",
            type: "success",
            text: "Offer submitted successfully, Property manager will get back to you very soon.",
          });
          this.getContractDetails();
        })
        .catch((err) => {
          this.submitLoading = false;
          let er = err.response.data;
          this.$notify({
            group: "notify",
            type: "error",
            text: err.message,
          });
        });
    },
    onUpload(e) {
      var files = e.target.files;
      if (!files.length) return;
      if (!files[0].type.match("application/pdf")) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "Only PDF type is supported",
        });
        return;
      }
      if (this.conditionFile == 1) {
        this.attachments = [];
      }
      for (var i = files.length - 1; i >= 0; i--) {
        this.attachments.push(files[i]);
        this.conditionFile = 1;
      }
      document.getElementById("attachments").value = [];
      this.data = new FormData();
      if (this.attachments.length > 0) {
        this.data.append("attachments", this.attachments[0]);
      }
      this.data.append("id", this.id);
      this.uploadbuttontext = "Uploading...";
      contractFileUpload(this.data)
        .then((res) => {
          this.uploadbuttontext = "Upload Revised Contract";
          this.item = (res.data && res.data.data) || [];
          this.offerPrice = this.item.final_price * this.item.contract_length;
          this.$notify({
            group: "notify",
            type: "success",
            text: "Contract uploaded successfully",
          });
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch((error) => {
        });
    },
  },
  validations: {
    offerPrice: {
      required,
    },
    phoneNumber: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(14),
    },
  },
  components: {
    VueNumeric,
  },
};
</script>
<style scoped lang="scss">
.contract-detail-upper {
  margin-bottom: 20px;
  @media (min-width: 1921px) {
    margin-bottom: 1.042vw;
  }
  .top-right {
    .userCard {
      padding: 15px;
      @media (min-width: 1921px) {
        padding: 0.781vw;
      }
      img {
        border-radius: 4px;
        @media (min-width: 1921px) {
          border-radius: 0.208vw;
        }
      }
      .user-texts {
        @media (min-width: 1200px) {
          padding-left: 0;
        }
        @media (max-width: 1199px) {
          padding-top: 12px;
          text-align: center;
        }
        p {
          margin-bottom: 0;
        }
        .userName {
          font-size: 16px;
          line-height: 1.4;
          font-weight: bold;
          @media (min-width: 1921px) {
            font-size: 0.833vw;
          }
        }
        .userRole {
          font-size: 14px;
          @media (min-width: 1921px) {
            font-size: 0.729vw;
          }
        }
        .user-text-top {
          margin-bottom: 15px;
          @media (min-width: 1921px) {
            font-size: 0.781vw;
          }
        }
        .user-text-bottom {
          .userMail {
            font-size: 14px;
            color: #0194b3;
            @media (min-width: 1921px) {
              font-size: 0.729vw;
            }
          }
        }
      }
    }
  }
}
.uploadFile {
  position: absolute;
  opacity: 0;
  z-index: -1;
  background-color: #daf6ff;
  display: none;
}

.btn1 {
  background-color: #daf6ff !important;
}
.cardBlue {
  background-color: #1f5269;
  color: white;
  padding: 15px;
  @media (min-width: 1921px) {
    padding: 0.781vw;
  }
  @media (max-width: 991px) {
    margin-bottom: 15px;
  }
  .formControl {
    background-color: transparent;
    color: white;
  }
  .fieldFloating__label {
    color: white;
  }
  p {
    line-height: 1.5;
    margin: 0;
    font-size: 14px;
    @media (min-width: 1921px) {
      font-size: 0.729vw;
    }
  }
  .small-p {
    font-weight: normal;
  }
  .med-p {
    font-weight: bold;
  }
  .align-right {
    text-align: right;
  }
}
.cardWhite {
  .layout-label {
    display: block;
    width: 90%;
    text-align: right;
  }
  .align-right {
    text-align: right;
  }
  label {
    color: #1f5269;
  }
}
.renewContract {
  margin-right: 0;
}

.bold {
  font-weight: bold;
}
.text-danger {
  color: #e55353 !important;
  font-size: 12px;
  margin-bottom: 10px;
}

.icon-blue {
  color: #0194b3;
}
.offer-card {
  padding: 15px;
  margin-bottom: 20px;
  @media (min-width: 1921px) {
    margin-bottom: 1.042vw;
    padding: 0.781vw;
  }
}
.download_note {
  font-size: 16px;
  @media (min-width: 1921px) {
    font-size: 0.833vw;
  }
}
input[type="tel"] {
  padding: 15px;
  width: 48%;
  margin-right: 10px;
  border: 1px solid grey;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}
.submit-offer {
  background-color: #daf6ff;
  margin-left: 10px;
  @media (max-width: 767.98px) {
    margin-left: 0;
  }
}
.cardWhite .button-allign:not(:last-child) {
  margin-right: 15px;
  margin-bottom: 10px;
}
@media (max-width: 568px) {
  .cardWhite .button-allign,
  .cardWhite .button-allign a,
  .cardWhite .btn1 {
    width: 100%;
    display: block;
    margin-right: 0 !important;
  }
  .cardWhite .btn1 {
    margin-bottom: 15px;
  }
}

.fieldFloating__label.required-field {
    color: #28485E;
    top: 0px !important;
}
</style>
