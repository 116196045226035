import request from "@/utils/request";

export function getContracts(page, perPage) {
    return request({
        url: "/api/contracts?perPage=" + perPage + "&page=" + page,
        method: "get",
    });
}
export function deleteContract(id) {
    return request({
        url: "/api/contracts/" + id,
        method: "delete",
    });
}
export function getContract(id) {
    return request({
        url: "/api/contracts/" + id,
        method: "get",
    });
}

export function postContract(data) {
    console.log('data in postContract : ', data);
    if (data.allow_submit === 0 || data.allow_submit === 1) {
        delete data.allow_submit;
    }
    return request({
        url: "/api/contracts",
        method: "post",
        data,
    });
}

export function putContract(id, data) {
    if (data.allow_submit === 0 || data.allow_submit === 1) {
        delete data.allow_submit;
    }
    return request({
        url: "/api/contracts/" + id,
        method: "put",
        data,
    });
}

export function getContractByUser(id) {
    return request({
        url: "/api/user-contracts/" + id,
        method: "get",
    });
}

export function contractFileUpload(data) {
    return request({
        url: "/api/contracts-file-upload",
        method: "post",
        data,
    });
}

export function makeOffer(data) {
    return request({
        url: "/api/store_contract_offer",
        method: "post",
        data,
    });
}

export function updateOfferStatus(data) {
    return request({
        url: "/api/update_offer_status",
        method: "post",
        data,
    });
}

export function getAllOffers(page, perPage, searchStr) {
    let data = {};
    return request({
        url:
            "/api/contract_offer?perPage=" +
            perPage +
            "&page=" +
            page +
            "&search=" +
            searchStr,
        method: "post",
        data,
    });
}
